<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
	  <div class="header">
		<img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
		<div class="base-info">
		  <van-image
			round
			width="20vw"
			height="20vw"
			:src="agentInfo.avatar ? agentInfo.avatar : defaultFace"
			fit="cover"
		  />
		  <div class="info">
			<span class="name">{{ agentInfo.nickName }}</span>
			<span class="item">ID: {{ agentInfo.incId }}</span>
			<span class="item" @click="onCopy(agentInfo.inviteCode)"
			  >邀請碼: {{ agentInfo.inviteCode }}</span
			>
			<span class="item" v-if="agentInfo.isTutor!=false">金牌導師</span>
		  </div>
		</div>
	  </div>
    <ul class="credit-menu">
	  <li>
	    <a @click="addTeacher()">
	      <span class="title" >
	        <i class="iconfont icon-qianbao-04" style="color: #3cadff" />
	          申請导师
	      </span>
	    </a>
	  </li>
	  
      <li>
        <a href="#/teacherRule">
          <span class="title">
            <i class="iconfont icon-qianbao-04" style="color: #3cadff" />
              導師規則
          </span>
        </a>
      </li>
<!-- 	  <li>
	    <a href="#/teacherLog">
	      <span class="title">
	        <i class="iconfont icon-jilu" style="color: #3cadff" />
	         申請導師列表
	      </span>
	    </a>
	  </li> -->
	<li>
	    <a @click="toTeacherSchedule()">
	      <span class="title">
	        <i class="iconfont icon-qianbao-02" style="color: #f5a624" />
	          当前導師任務完成情况
	      </span>
	    </a>
	  </li>
    </ul>
  </div>

<!--  <div
    class="pc-my-page"
    :style="'--aog-currency:\'' + SYSLANG.crrency + '\';'"
  >
    <MyMenu :agentInfo="agentInfo" pagename="credit" />
    <div class="main">
      <div class="credit-info">
        <div class="item">
          <span class="num prie">{{ agentInfo.Balance}}</span>
          {{ PAGELANG.credit }}
        </div>
        <div class="item">
          <span class="num price">{{ agentInfo.Credit }}</span>
          {{ PAGELANG.累计购货额 }}
        </div>
        <div class="item">
          <span class="num price">{{ agentInfo.Expend }}</span>
          {{ PAGELANG.已提货物价值 }}
        </div>
      </div>
      <ul class="credit-menu">
        <li>
          <a href="#/credit/logs">
            <span class="title">
              <i class="iconfont icon-qianbao-02" />
              {{ PAGELANG.货款明细 }}
            </span>
            <span class="price">{{ agentInfo.Balance }}</span>
          </a>
        </li>
        <li>
          <a href="#/credit/recharge">
            <span class="title">
              <i class="iconfont icon-qianbao-04" />
              {{ PAGELANG.类型充值 }}
            </span>
          </a>
        </li>
        <li>
          <a href="#/credit/rechargelogs">
            <span class="title">
              <i class="iconfont icon-jilu" />
              {{ PAGELANG.充值记录 }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div -->>
</template>

<script>
import { closeToast, showLoadingToast, showToast,showConfirmDialog } from "vant";
import { getCookie } from "../util";

export default {
 
  computed:{
	   FiltergoodsMoney() {
	        return this.agentInfo.goodsMoney/100
	   },
	   FilterinMoney(){
		   return this.agentInfo.inMoney/100
	   },
	   FilteroutMoney(){
		   return this.agentInfo.outMoney/100
	   }
		 
  },
  data() {
    return {
      agentInfo: {},
	  isAddTeacher:false,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });
	
	this.axios
	  .get(this.actions.memeberTeam)
	  .then((res) => {
			  if(res.data.code!=200){
				   showToast({
						message:res.data.message
				   })
			  }else{
				  
				  //this.agentInfo = res.data;
				  this.lv1count=res.data.data.agentBeansB.length;
				  this.lv2count=res.data.data.agentBeansC.length;
				  this.lv3count=res.data.data.agentBeansD.length;
				  if(this.lv1count<5 || this.lv2count<10){
					  this.isAddTeacher=false
				  }else{
					  this.isAddTeacher=true
				  }
				  closeToast();
			  }
		//closeToast();
	  })
	  .catch((error) => {
		console.log("views.AgentGroup.init.error", error);
		closeToast();
		//this.init();
	  }); 
	
    this.axios
      .get(this.actions.memberInfo)
      .then((res) => {
        closeToast();
        let { code, message, data } = res.data;
        console.log(code, message);
        if (code == 200) {
          this.agentInfo = data;
        } else {
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      })
      .catch((error) =>
        //请求失败，触发catch中的函数 可省略
        console.log(error)
      );
  },
  methods:{
	  toTeacherSchedule(){
		   if(this.agentInfo.isTutor==false){
		   	showToast({message:"當前導師任務尚未開啟!",});
		   	return
		   }else{
			  this.$router.push({
			    path: "/TeacherSchedule",
			  });
			  return 
		   }
	  },
	  addTeacher() {
		if(this.agentInfo.isTutor!=false){
			this.$router.push({
			  path: "/teacherLog",
			});
			return
		}
		
		
		if(this.isAddTeacher==false){
			showToast({message:"您不符合申請導師條件",});
			return
		}
		  
	    showConfirmDialog({
	      title:this.SYSLANG.dialogtitle,
	      message:"是否申請導師",
	      confirmButtonText: this.SYSLANG.queding,
	      cancelButtonText: this.SYSLANG.quxiao,
	    })
	      .then(() => {
	        showLoadingToast({
	          message:"申請中",
	          duration: 0,
	          forbidClick: true,
	        });
	        this.axios
	          .get(this.actions.addTeacher)
	          .then((res) => {
	            closeToast();
	            let { code, message } = res.data;
	  		  if(code==200){
	  			  showToast({
	  				   message:message,
	  				   onClose:()=>{
	  					   this.init();
	  				   },
	  				   orbidClick: true,
	  			  })
	  		  }else{
	  			 showToast({
	  			 		message:message,
	  			 		orbidClick: true,
	  			 }) 
	  		  }
	          })
	          .catch((error) => {
	            console.log("views.Address.onDelete.error", error);
	            closeToast();
	          });
	      })
	      .catch(() => {});
	  },  
  }
};
</script>


<style src="../assets/css/credit.css" scoped></style>



<style scoped>
@media (max-width: 678px) {
  .credit-page {
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 16px;
    background-color: #f7f7f9;
    --aog-credit-menu-bottom-margin: 10px;
    --van-cell-group-inset-padding: 0;
    --van-cell-right-icon-color: #666;
  }

  .credit-page .header {
    position: relative;
    line-height: 0;
    margin-bottom: 16px;
  }

  .credit-page .header .member-bg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .credit-page .header .base-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 4vw 15vw 4vw;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .credit-page .header .base-info .info {
    width: 0;
    padding-left: 3vw;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 26px;
  }

  .credit-page .header .base-info .info .name {
    font-size: 18px;
    color: #fff;
  }

  .credit-page .header .base-info .info .item {
    background-color: var(--van-card-price-color);
    color: #fff;
    display: inline-block;
    padding: 0 10px;
    line-height: 24px;
    font-size: 13px;
    margin: 3px 6px 3px 0;
    border-radius: 6px;
  }

  >>> .van-icon-aog {
    color: var(--van-card-price-color);
  }
}

@media (min-width: 679px) {
  .agroup-page {
    display: none;
  }

  .pc-my-page .main {
    padding: 36px 30px;
  }

  .pc-my-page .main .credit-info .item {
    cursor: pointer;
  }
}
</style>